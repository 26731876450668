import React,{useState,useEffect} from "react"
import {useStaticQuery,graphql} from "gatsby"
import Layout from "../layout/layout"
import Post from "../components/PostStyle"
const BlogPage=() =>{
    const data = useStaticQuery(
        graphql`
          query {
            allMarkdownRemark(limit: 60, filter: {frontmatter: {template: {eq: "post"}}}, sort: {fields: [frontmatter___date], order: DESC}) {
              totalCount
              edges {
                node {
                  frontmatter {
                    title
                    date(formatString: "Do MMM, YYYY")
                    template
                    categories
                    thumbnail
                  }
                  fields {
                    slug
                  }
                }
              }
            }
            categories: allMarkdownRemark(limit: 60) {
              group(field: frontmatter___categories) {
                fieldValue
                totalCount
              }
            }
          }
        `)
  const [name, setName] = useState('')
  const [filteredPosts,setFilter]=useState({})
  const [currentCategories,setCategories]=useState([])
  const handleChange = e => {
    //console.log(e.target.value+'===wewweetw')
      setName(e.target.value)
  }
  useEffect(()=>{
      let filteredPost = data.allMarkdownRemark.edges.filter(post =>
        post.node.frontmatter.title.toLowerCase().includes(name.toLowerCase())
      )
      
      if (currentCategories.length > 0) {
        filteredPost= filteredPost.filter(post =>
            post.node.frontmatter.categories && currentCategories.every(cat => post.node.frontmatter.categories.includes(cat))
        )
      }
      //console.log("Test"+filteredPost.length)
      if(filteredPost.length>=0)setFilter(filteredPost)
  },[name,currentCategories,data.allMarkdownRemark.edges])

const updateCategories = (catvalue,active) => {
  if(!active) setCategories([...currentCategories,catvalue])
  else setCategories(currentCategories.filter(cat=>catvalue!==cat))
}
    return(
        <Layout title="Tech Articles">
            <h1>Tech Articles</h1>
            <div className="category-container">
            {data.categories.group.map(category => {
              const active = currentCategories.includes(category.fieldValue)
              return (
                <div className={`category-filter ${active ? 'active' : ''}`} key={category.fieldValue}
                  onClick={async ()=>{updateCategories(category.fieldValue,active)}}>
                  {category.fieldValue} : {category.totalCount}
                </div>
              )
            })}
          </div>
            <div className="search-container">
            <input className="search" type="text" name="searchTerm" placeholder="Search in articles..." onChange={handleChange}/>
            <div className="filter-count">{filteredPosts.length} <span className="vmiddle big">/</span> {data.allMarkdownRemark.totalCount}</div>
          </div>
            <section className="posts">{(filteredPosts.length>=0?filteredPosts:data.allMarkdownRemark.edges).map((edge,index)=>{
                return(
                <div className="post" key={index}>
                    <Post postEdges={edge}/>
                </div>
                )
            }
            )}</section>
            <p>Well, I have lot many things to share. hope, you like what you are going to read now or in future...!</p>
        </Layout>
    )
}
export default BlogPage;